const lerp = (v1, v2, e) => {
  return v1 * (1 - e) + v2 * e
}

class Dot {
  constructor(ctx, options) {
    this.ctx = ctx
    this.options = {
      size: 20,
      color: 'black',
      ease: 0.06,
      ...options,
    }
    this.position = { x: 0, y: 0 }
  }

  draw(mouse) {
    const { x: mouseX, y: mouseY } = mouse
    const { size, color, ease } = this.options

    this.position.x = lerp(mouseX, this.position.x, ease)
    this.position.y = lerp(mouseY, this.position.y, ease)

    const { x, y } = this.position
    this.ctx.beginPath()
    this.ctx.fillStyle = color
    this.ctx.arc(x, y, size, 0, Math.PI * 2)
    this.ctx.fill()

    return this.position
  }
}

/**
 * @param { Object } options - Cursor 選項
 * @param { Object } [options.dots={
          '#E7242C': 70,
          '#ED7959': 60,
          '#3EA3F0': 50,
          '#FC1E25': 40,
          '#FE7BA6': 30,
          '#93A3EE': 20,
          '#FEFF00': 10,
        }] - 顏色及數量
 * @param { Number } [options.size='20'] - 鼠標大小
 */
export default class {
  constructor(el = document.body, options) {
    if (el instanceof Element) {
      this.el = el
      this.options = {
        dots: {
          '#00FF00': 70,
          '#EE91EF': 60,
          '#FFD96B': 50,
          '#EAFC00': 40,
          '#AC34F5': 30,
          '#B4EC3C': 20,
          '#D8E800': 10,
        },
        size: 20,
        ...options,
      }
      this.reqRenders = []
      this.mouse = { x: 0, y: 0 }
      this.render = this.render.bind(this)
      this.mousemove = this.mousemove.bind(this)
      this.resize = this.resize.bind(this)

      this.createSketch()
      this.createCursorDots()
      this.render()
      window.addEventListener('mousemove', this.mousemove)
      window.addEventListener('resize', this.resize)
    }
  }

  createSketch() {
    this.canvas = document.createElement('canvas')
    this.canvas.className = 'cursor'
    this.ctx = this.canvas.getContext('2d')
    this.resize()
    this.el.appendChild(this.canvas)
  }

  createCursorDots() {
    const { dots, size } = this.options
    this.dots = []

    let index = 0
    for (const color in dots) {
      for (let i = 0; i < dots[color]; i++) {
        index++
      }
    }
    for (const color in dots) {
      for (let i = 0; i < dots[color]; i++) {
        this.dots.push(
          new Dot(this.ctx, {
            color,
            size,
            ease: 0.07 * (280 / index),
          })
        )
      }
    }

    this.reqRenders.push(() => {
      const len = this.dots.length
      let mouse = this.mouse
      for (let i = len - 1; i > -1; i--) {
        const dot = this.dots[i]
        mouse = dot.draw(mouse)
      }
    })
  }

  mousemove({ clientX, clientY }) {
    const { width, height } = this.viewport
    this.mouse.x = clientX - width / 2
    this.mouse.y = clientY - height / 2
  }

  resize() {
    const { width, height, retinaWidth, retinaHight } = this.viewport
    this.canvas.width = retinaWidth
    this.canvas.height = retinaHight
    this.canvas.style.width = `${width}px`
    this.canvas.style.height = `${height}px`
  }

  render() {
    this.reqID = window.requestAnimationFrame(this.render)
    const { ctx } = this
    const { retinaWidth, retinaHight, dpr } = this.viewport

    ctx.clearRect(0, 0, retinaWidth, retinaHight)
    ctx.save()
    ctx.translate(retinaWidth / 2, retinaHight / 2)
    ctx.scale(dpr, dpr)

    for (let i = 0; i < this.reqRenders.length; i++) {
      this.reqRenders[i](this.reqID)
    }

    ctx.restore()
  }

  remove() {
    window.cancelAnimationFrame(this.reqID)
  }

  destroy() {
    this.remove()
    this.el.removeChild(this.canvas)

    window.removeEventListener('mousemove', this.mousemove)
    window.removeEventListener('resize', this.resize)
  }

  get viewport() {
    const { width, height } = this.el.getBoundingClientRect()
    const dpr = Math.min(window.devicePixelRatio, 1.5)
    return {
      width,
      height,
      retinaWidth: (width * dpr) >> 0,
      retinaHight: (height * dpr) >> 0,
      dpr,
    }
  }
}
