import gsap from 'gsap'
import Matter from 'matter-js'

class Rect {
  constructor(x, y, width, height, options) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.options = {
      ...options,
    };

    this.bodies = Matter.Bodies.rectangle(this.x, this.y, this.width, this.height, this.options);
  }

  draw(ctx) {
    const {
      angle,
      position: { x, y },
      render: { fillStyle, strokeStyle },
    } = this.bodies;

    ctx.fillStyle = fillStyle;
    ctx.strokeStyle = strokeStyle;

    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(angle);
    ctx.translate(-this.width / 2, -this.height / 2);
    ctx.fillRect(0, 0, this.width, this.height);
    ctx.restore();
  }
}

class Circle {
  constructor(x, y, radius, options) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.opacity = 1;
    this.options = {
      ...options,
    };

    this.bodies = Matter.Bodies.circle(this.x, this.y, this.radius, this.options);
  }

  draw(ctx) {
    const {
      angle,
      position: { x, y },
      render: { fillStyle, strokeStyle },
    } = this.bodies;

    ctx.fillStyle = fillStyle;
    ctx.strokeStyle = strokeStyle;

    ctx.save();
    ctx.globalAlpha = this.opacity;
    ctx.translate(x, y);
    ctx.rotate(angle);
    ctx.beginPath();
    ctx.arc(0, 0, this.radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.restore();
  }

  reset() {
    this.fade(1);
    return new Promise((resolve) => {
      Matter.Body.setVelocity(this.bodies, { x: 0, y: 0 });
      this.updatePosition(resolve);
    });
  }

  fade(opacity) {
    gsap.to(this, {
      opacity,
    });
  }

  updatePosition(resolve) {
    const { x, y } = this.bodies.position;
    if (x >> 0 !== 0 || y >> 0 !== 0) {
      window.requestAnimationFrame(this.updatePosition.bind(this, resolve));
      const dx = x - this.x;
      const dy = y - this.y;
      Matter.Body.setPosition(this.bodies, { x: (x - dx * 0.1) >> 0, y: (y - dy * 0.1) >> 0 });
      return;
    }
    resolve();
  }
}

class Sketch {
  constructor(el = document.body) {
    if (el instanceof Element) {
      this.el = el;
      this.reqRenders = [];
      this.resizes = [];
      this.render = this.render.bind(this);
      this.resize = this.resize.bind(this);

      this.resizes.push(() => {
        const {
          width, height, retinaWidth, retinaHight,
        } = this.viewport;
        this.canvas.width = retinaWidth;
        this.canvas.height = retinaHight;
        this.canvas.style.width = `${width}px`;
        this.canvas.style.height = `${height}px`;
      });

      this.createSketch();
      this.render();
      window.addEventListener('resize', this.resize);
    }
  }

  createSketch() {
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.resize();
    this.el.appendChild(this.canvas);
  }

  resize() {
    for (let i = 0, len = this.resizes.length; i < len; i++) {
      this.resizes[i]();
    }
  }

  render() {
    this.reqID = window.requestAnimationFrame(this.render);
    const { ctx } = this;
    const { retinaWidth, retinaHight, dpr } = this.viewport;

    ctx.clearRect(0, 0, retinaWidth, retinaHight);
    ctx.save();
    ctx.translate(retinaWidth / 2, retinaHight / 2);
    ctx.scale(dpr, dpr);

    for (let i = 0; i < this.reqRenders.length; i++) {
      this.reqRenders[i](this.reqID);
    }

    ctx.restore();
  }

  remove() {
    window.cancelAnimationFrame(this.reqID);
  }

  destroy() {
    this.remove();

    while (this.el.hasChildNodes()) {
      this.el.removeChild(this.el.lastChild);
    }

    window.removeEventListener('resize', this.resize);
  }

  get viewport() {
    const { width, height } = this.el.getBoundingClientRect();
    const dpr = Math.min(window.devicePixelRatio, 1.5);
    return {
      width,
      height,
      retinaWidth: (width * dpr) >> 0,
      retinaHight: (height * dpr) >> 0,
      dpr,
    };
  }
}

/**
 * @param { Object } options - PhysicalWorld 選項
 * @param { String } [options.publicPath='./'] - 基本src路徑
 * @param { String } [options.mode='pc'] - 模式 pc or mb 兩種
 * @param { String } [options.className='banner-canvas'] - css className
 * @param { Number } [options.cursorRadius=20] - 滑鼠範圍
 * @param { Number } [options.ballSize=2] - 球大小
 * @param { Number } [options.time=30000] - 最大時間
 * @param { Number } [options.actionTime=4000] - 動畫時間
 * @param { Number } [options.collisionTime=30] - 碰撞次數
 */
export default class extends Sketch {
  constructor(el, options) {
    super(el);

    this.options = {
      publicPath: '/Taishin19/++plone++taishin.award19/static/images/',
      mode: 'pc',
      className: 'banner-canvas',
      cursorRadius: 20,
      ballSize: 40,
      time: 30000,
      actionTime: 5000,
      collisionTime: 15,
      ...options,
    };
    this._isAction = false;
    this._isCollision = false;
    this._currIndex = 0;
    this._prevIndex = 0;
    this._collisionTime = 0;
    this.data = [];
    this.engine = Matter.Engine.create();
    this.engineWorld = this.engine.world;
    this.engineWorld.gravity.scale = 0.0001;
    this.engineWorld.gravity.x = this.engineWorld.gravity.y = 0;
    this.bodies = [];

    const { ballSize, mode } = this.options;
    this.createElement();
    this.createMouseCollision();
    this.createDeviceorientation();
    this.mode = mode;
    this.ball = this.addBall(0, 0, ballSize, {
      restitution: 0.9,
      mass: 0.1,
      inverseMass: 1 / 0.1,
      friction: 0.01,
      frictionAir: 0.01,
      render: {
        fillStyle: '#D8E800',
      },
    });
    this.wall = this.createBounding();

    Matter.Runner.run(this.engine);
    Matter.World.add(this.engineWorld, []);

    this.reqRenders.push(() => {
      for (let i = 0, len = this.bodies.length; i < len; i++) {
        const bodies = this.bodies[i];

        bodies.draw(this.ctx);
      }
      for (let i = 0, len = this.wall.length; i < len; i++) {
        const { collided } = Matter.SAT.collides(this.ball.bodies, this.wall[i].bodies);
        if (collided) {
          this.collisionTime++;
        }
      }
    });

    this.resizes.push(() => {
      Matter.Body.setPosition(this.ball.bodies, { x: 0, y: 0 });
    });
  }

  changeMode(mode) {
    if (mode === 'pc' || mode === 'mb') {
      this.mode = mode;
    }
  }

  createElement() {
    const { publicPath, className, ballSize } = this.options;
    const targets = this.el.querySelectorAll(`.${className}__img:not(.-default)`);

    const remind = document.createElement('div');
    remind.className = `${className}__remind`;
    remind.innerHTML = `
    <svg class="${className}__remind-pc" width="${
  ballSize * 5
}" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M15.4983 6.33463H14.656L12.9951 3.11719H13.8885L15.0946 5.51524L16.3007 3.11719H17.1816L15.4983 6.33463Z"
          fill="#808080"
        />
        <path
          d="M14.6333 0V1.93439V3.79396V5.72835H15.569V3.79396V1.93439V0H14.6333Z"
          fill="#808080"
        />
      </g>
      <g>
        <path
          d="M14.6809 23.668H15.5232L17.1841 26.8854H16.2882L15.0821 24.4874L13.8773 26.8854H12.9951L14.6809 23.668Z"
          fill="#808080"
        />
        <path
          d="M15.5468 30.0006V28.0662V26.2066V24.2734H14.6099V26.2066V28.0662V30.0006H15.5468Z"
          fill="#808080"
        />
      </g>
      <g>
        <path
          d="M23.5684 15.4643V14.6351L26.8365 13V13.8819L24.4007 15.0693L26.8365 16.2555V17.1239L23.5684 15.4643Z"
          fill="#808080"
        />
        <path
          d="M30.0001 14.6133H28.0364H26.1475H24.1826V15.5357H26.1475H28.0364H30.0001V14.6133Z"
          fill="#808080"
        />
      </g>
      <g>
        <path
          d="M6.43176 14.6584V15.4888L3.16357 17.1239V16.242L5.59945 15.0546L3.16357 13.8672V13L6.43176 14.6584Z"
          fill="#808080"
        />
        <path d="M0 15.5149H1.9649H3.8538H5.8187V14.5938H3.8538H1.9649H0V15.5149Z" fill="#808080" />
      </g>
    </svg>
    <img class="${className}__remind-mb" src="${publicPath}mb.svg" width="${ballSize}"></img>
    <div class="${className}__remind-again"></div>
    `;
    this.el.appendChild(remind);

    if (targets && targets.length) {
      this.data = targets || [];

      const content = document.createElement('div');
      content.className = `${className}__main`;
      content.innerHTML = `
        <p class="${className}__title -zh"></p>
        <p class="${className}__subtitle -zh"></p>
        <p class="${className}__title -en"></p>
        <p class="${className}__subtitle -en"></p>
        <a
          class="${className}__link"
          target="_blank"
          rel="noopener noreferer nofollow"
        >
          <div class="${className}__link-icon">
            <svg width="25" height="22" viewBox="0 0 25 22" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.4432 3.65128e-05H5.33478C5.18873 3.65128e-05 5.04866 0.0580555 4.94538 0.16133C4.84211 0.264605 4.78409 0.404676 4.78409 0.550728V4.27729H5.88547V1.09203H23.8987V20.6979H5.87922V17.5221H4.77783V21.258C4.7803 21.4035 4.84027 21.5421 4.94463 21.6436C5.04899 21.745 5.18926 21.801 5.33478 21.7993H24.4494C24.5955 21.7993 24.7356 21.7413 24.8388 21.638C24.9421 21.5347 25.0001 21.3947 25.0001 21.2486V0.541342C24.9976 0.395832 24.9377 0.257217 24.8333 0.155787C24.729 0.0543578 24.5887 -0.00163822 24.4432 3.65128e-05V3.65128e-05Z"/>
              <path d="M9.56202 15.1127L10.3411 15.8918L15.3349 10.8981L10.3411 5.9043L9.56202 6.6834L13.226 10.3474H0V11.4488H13.226L9.56202 15.1127Z"/>
            </svg>
          </div>
          <p>前往作品</p>
        </a>
      `;
      this.el.appendChild(content);
    }
  }

  createBounding() {
    const { width: vpWidth, height: vpHeight } = this.viewport;
    const thickness = 3000;
    const options = {
      isStatic: true,
      render: {
        fillStyle: 'transparent',
      },
    };

    const top = this.addRect(0, -vpHeight / 2 - thickness / 2, vpWidth, thickness, options);
    const bottom = this.addRect(0, vpHeight / 2 + thickness / 2, vpWidth, thickness, options);
    const left = this.addRect(-vpWidth / 2 - thickness / 2, 0, thickness, vpHeight, options);
    const right = this.addRect(vpWidth / 2 + thickness / 2, 0, thickness, vpHeight, options);

    this.resizes.push(() => {
      const { width: vpWidth, height: vpHeight } = this.viewport;

      Matter.Body.setPosition(top.bodies, { x: 0, y: -vpHeight / 2 - thickness / 2 });
      Matter.Body.setPosition(bottom.bodies, { x: 0, y: vpHeight / 2 + thickness / 2 });
      Matter.Body.setPosition(left.bodies, { x: -vpWidth / 2 - thickness / 2, y: 0 });
      Matter.Body.setPosition(right.bodies, { x: vpWidth / 2 + thickness / 2, y: 0 });
    });

    return [top, bottom, left, right];
  }

  createMouseCollision() {
    this.mouse = {
      curr: { x: 0, y: 0 },
      prev: { x: 0, y: 0 },
    };
    this.mouseForce = { x: 0, y: 0 };
    this.mousemove = this.mousemove.bind(this);
  }

  createDeviceorientation() {
    this.deviceorientation = this.deviceorientation.bind(this);
    this.getDeviceorientation = this.getDeviceorientation.bind(this);
  }

  getDeviceorientation() {
    if (
      window.DeviceOrientationEvent !== undefined
      && typeof window.DeviceOrientationEvent.requestPermission === 'function'
    ) {
      window.DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === 'granted') {
            window.addEventListener('deviceorientation', this.deviceorientation);
            this.startCollision();
          }
        })
        .catch((error) => {
          console.error(
            'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:',
            error,
          );
        });
    } else {
      window.addEventListener('deviceorientation', this.deviceorientation);
      this.startCollision();
    }
  }

  mousemove({ offsetX, offsetY }) {
    const { width: vpWidth, height: vpHeight } = this.viewport;
    this.mouse.curr.x = offsetX - vpWidth / 2;
    this.mouse.curr.y = offsetY - vpHeight / 2;
    this.mouseForce.x = (this.mouse.curr.x - this.mouse.prev.x) * 0.00015;
    this.mouseForce.y = (this.mouse.curr.y - this.mouse.prev.y) * 0.00015;

    const { ballSize, cursorRadius } = this.options;
    const { x: mX, y: mY } = this.mouse.curr;
    const { x: bX, y: bY } = this.ball.bodies.position;

    const diff = ((bX - mX) ** 2 + (bY - mY) ** 2) ** 0.5;
    if (diff - cursorRadius < ballSize) {
      if (!this.isAction) {
        Matter.Body.applyForce(this.ball.bodies, this.mouse.curr, this.mouseForce);

        this.startCollision();
      }
    }

    this.mouse.prev.x = this.mouse.curr.x;
    this.mouse.prev.y = this.mouse.curr.y;
  }

  deviceorientation({ alpha, beta, gamma }) {
    this.engineWorld.gravity.x = gamma;
    this.engineWorld.gravity.y = beta;
  }

  addBall(x, y, radius, options) {
    const ball = new Circle(x, y, radius, options);
    Matter.World.add(this.engineWorld, ball.bodies);
    this.bodies.push(ball);
    return ball;
  }

  addRect(x, y, width, height, options) {
    const rect = new Rect(x, y, width, height, options);
    Matter.World.add(this.engineWorld, rect.bodies);
    this.bodies.push(rect);
    return rect;
  }

  removeBody(body) {
    if (body instanceof Circle || body instanceof Rect) {
      const index = this.bodies.indexOf(body);
      if (~index) {
        Matter.World.remove(this.engineWorld, body.bodies);
        this.bodies.splice(index, 1);
      }
    }
  }

  startCollision() {
    if (!this.isCollision) {
      this.isCollision = true;
      const { time } = this.options;

      this.collisionTimeout = setTimeout(() => {
        this.startAction();
      }, time);
    }
  }

  startAction() {
    const { actionTime } = this.options;

    if (!this.isAction) {
      this.isAction = true;
      clearTimeout(this.collisionTimeout);

      let rand = 0;
      if (this.data.length) {
        rand = (Math.random() * this.data.length) >> 0;
        while (rand === this.currIndex) rand = (Math.random() * this.data.length) >> 0;
      }

      this.ball.fade(0);
      for (const wall of this.wall) {
        this.removeBody(wall);
      }
      window.removeEventListener('deviceorientation', this.deviceorientation);

      this.setDisplayTarget(rand);
      this.isCollision = false;

      setTimeout(async () => {
        this.el.classList.remove('-first');
        this.engineWorld.gravity.x = this.engineWorld.gravity.y = 0;
        await this.ball.reset();
        this.wall = this.createBounding();
        this.collisionTime = 0;
        this.isAction = false;
      }, actionTime);
    }
  }

  setDisplayTarget(index = 0) {
    if (this.data.length) {
      this.prevIndex = this.currIndex;
      this.currIndex = index;
    }
  }

  destroy() {
    const remind = document.querySelector(`.${className}__remind`);
    remind.removeEventListener('click', this.getDeviceorientation);
    window.removeEventListener('deviceorientation', this.deviceorientation);
    this.el.removeEventListener('mousemove', this.mousemove);
    super.destroy();
  }

  get isAction() {
    return this._isAction;
  }

  set isAction(value) {
    this._isAction = value;

    const { className } = this.options;
    const remind = document.querySelector(`.${className}__remind`);
    const content = document.querySelector(`.${className}__main`);
    if (value) {
      content.classList.remove('-active');
      return;
    }
    remind.classList.add('-active');
  }

  get isCollision() {
    return this._isCollision;
  }

  set isCollision(value) {
    this._isCollision = value;

    const { className } = this.options;
    const remind = document.querySelector(`.${className}__remind`);
    const content = document.querySelector(`.${className}__main`);

    if (value) {
      remind.classList.remove('-active');
      return;
    }
    content.classList.add('-active');
  }

  get mode() {
    return this.options.mode;
  }

  set mode(value) {
    this.options.mode = value;

    const { mode, className } = this.options;

    this.el.className = `${className} -${mode} -first`;
    const remind = document.querySelector(`.${className}__remind`);
    remind.className = `${className}__remind -active`;
    this.resize();

    if (mode === 'pc') {
      this.el.addEventListener('mousemove', this.mousemove);
      remind.removeEventListener('click', this.getDeviceorientation);
      window.removeEventListener('deviceorientation', this.deviceorientation);
      return;
    }
    this.el.removeEventListener('mousemove', this.mousemove);
    remind.addEventListener('click', this.getDeviceorientation);
  }

  get collisionTime() {
    return this._collisionTime;
  }

  set collisionTime(value) {
    this._collisionTime = value;
    const { collisionTime } = this.options;

    if (value === collisionTime) {
      this.startAction();
    }
  }

  get currIndex() {
    return this._currIndex;
  }

  set currIndex(value) {
    this._currIndex = value;
    const { className } = this.options;
    if (this.data.length) {
      const target = this.data[value];
      target.classList.add('-active');

      const titleZh = document.querySelector(`.${className}__title.-zh`);
      const subtitleZh = document.querySelector(`.${className}__subtitle.-zh`);
      const titleEn = document.querySelector(`.${className}__title.-en`);
      const subtitleEn = document.querySelector(`.${className}__subtitle.-en`);
      const link = document.querySelector(`.${className}__link`);

      titleZh.innerText = target.dataset.titleZh;
      subtitleZh.innerText = target.dataset.subtitleZh;
      titleEn.innerText = target.dataset.titleEn;
      subtitleEn.innerText = target.dataset.subtitleEn;
      link.href = target.dataset.href;
    }
  }

  get prevIndex() {
    return this.prevIndex;
  }

  set prevIndex(value) {
    this._prevIndex = value;
    if (this.data.length) {
      const target = this.data[value];
      target.classList.remove('-active');
    }
  }
}
